import React from "react"
import { Link } from "gatsby"

import YAMLdata from "./home-banner.yaml"

const HomeBanner = () => {
  return (
    <section className="home_banner_area">
      <div className="banner_inner">
        <div className="home_center">
          <div className="banner_content text-center">
            <h5>{YAMLdata.heading}</h5>
            <h2>{YAMLdata.title}</h2>
            <p>{YAMLdata.content}</p>
            <Link
              to="/enterprise-iot-assistance"
              className="banner_btn circle arrow"
            >
              Find out how?
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}
export default HomeBanner
