import React from "react"
import { Link } from "gatsby"
import DynamicImage from "../components/dynimage"

const HomeSolutions = () => {
  return (
    <section className="latest_blog_area p_120">
      <div className="container">
        <div className="main_title">
          <h2>What we offer...</h2>
        </div>
        <div className="row latest_blog_inner">
          <div className="col-lg-4">
            <div className="causes_item">
              <div className="causes_img">
                <Link to="/enterprise-iot-assistance">
                  <DynamicImage src="solutions/home-solutions_01.jpg" />
                </Link>
              </div>
              <div className="causes_text text-center">
                <Link to="/enterprise-iot-assistance">
                  <h4 className="text-uppercase">Enterprise IOT Assistance</h4>
                </Link>
                <Link
                  to="/enterprise-iot-assistance"
                  className="genric-btn circle arrow"
                >
                  View More <span class="lnr lnr-arrow-right"></span>
                </Link>
                {/* <p>
                  Globally the enterprise IOT division face the challenges of
                  selecting technologies of diverse maturity level. The roaming
                  solutions are provided by multi vendors right from IOT modules
                  to sim cards to connectivity and the roaming capabilities.
                </p> */}
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="causes_item">
              <div className="causes_img">
                <Link to="/private-lte">
                  <DynamicImage src="solutions/home-solutions_02.jpg" />
                </Link>
              </div>
              <div className="causes_text text-center">
                <Link to="/private-lte">
                  <h4 className="text-uppercase">Private LTE</h4>
                </Link>
                <Link to="/private-lte" className="genric-btn circle arrow">
                  View More <span class="lnr lnr-arrow-right"></span>
                </Link>
                {/* <p>
                  NgNapps Private LTE and 5G NSA PaaS offering allows clients to
                  host packet core network products and solutions. The managed
                  part of the offering ensures that our network specialists will
                  install and commission products directly into a Cloud
                  environment and then seamlessly provide ongoing support.
                </p> */}
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="causes_item">
              <div className="causes_img">
                <Link to="/virtual-evolved-packet-core">
                  <DynamicImage src="solutions/home-solutions_04.jpg" />
                </Link>
              </div>
              <div className="causes_text text-center">
                <Link to="/virtual-evolved-packet-core">
                  <h4 className="text-uppercase">
                    virtual evolved packet core (vEPC)
                  </h4>
                </Link>
                <Link
                  to="/virtual-evolved-packet-core"
                  className="genric-btn circle arrow"
                >
                  View More <span class="lnr lnr-arrow-right"></span>
                </Link>
                {/* <p>
                  NgNapps virtual evolved packet core
                  <abbr title="virtual evolved packet core"> (vEPC) </abbr>
                  solution offers mobile network operators the agility and
                  flexibility necessary to keep up with traffic demand, to scale
                  their networks and deliver differentiated services tailored to
                  specific use cases such as: Consumer, IoT, private LTE, Wi-Fi,
                  VoLTEand 5G services.
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default HomeSolutions
