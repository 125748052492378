import React from "react"
// import { Link } from "gatsby"
// import DynamicImage from "../components/dynimage"

const HomeProducts = () => {
  return (
    <section className="product_area p_120" id="how-it-works">
      <div className="container">
        <div className="main_title">
          <h2>Our Product Range</h2>
          <p>
            Our products are designed to generate growth in revenues, reduce
            operational cost and help our clients stay ahead of competition by
            setting new benchmarks for business processes and growth. The
            portfolio is a converged offering, that enables our clients to boost
            product uptake and cross-product offerings. Tailored and customized
            product modules for efficient operations with a focus on minimizing
            capex.
          </p>
        </div>
        <div className="feature_inner row">
          <div className="col-lg-4 col-md-6 d-flex align-content-stretch">
            <div className="feature_item text-center">
              <i className="flaticon-depth-perception"></i>
              <h4>Ngnapps Private LTE</h4>
              <p>
                Solution is designed to serve specific enterprise business,
                government or educational purposes. It provides global mobile
                operators with a new revenue generating opportunity for private
                and public enterprises in search to replace their legacy,
                hardware-centric networks with cloud-native, easy to deploy and
                maintain 4G/LTE solution.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 d-flex align-content-stretch">
            <div className="feature_item text-center">
              <i className="flaticon-internet"></i>
              <h4>Virtual EPC</h4>
              <p>
                Virtual evolved packet core (vEPC) solution offers mobile
                network operators the agility and flexibility necessary to keep
                up with traffic demand, to scale their networks and deliver
                differentiated services tailored to specific use cases such as:
                Consumer, IoT, private LTE, Wi-Fi, and VoLTE. It has 3GPP
                Compliant SGSN, MME, GGSN, SGW and PGW to support network
                services for 2G, 3G, 4G, LTE, and VoLTE.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 d-flex align-content-stretch">
            <div className="feature_item text-center">
              <i className="flaticon-signal-tower"></i>
              <h4>DSC(Diameter signaling controller)</h4>
              <p>
                Ngnapps DSC is an intelligent signaling framework that
                streamlines and routes all Diameter-based IP signaling
                communication within the LTE and IMS networks. Ngnapps DSC
                allows operator to setup policy rules that securely exchange
                Diameter messages across partner networks over IPX (IP network).
                Mobile Virtual Network Operators (MVNOs) can also use DRA/DEAs
                to communicate with the home network, for example OCSs to
                communicate with partner H-PLMN.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default HomeProducts
