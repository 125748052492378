import React from "react"

const HomeServices = () => {
  return (
    <section className="feature_area p_120">
      <div className="container">
        <div className="main_title">
          <h2>Roaming Engineering Services</h2>
          <p>
            This solution helps enterprises cut down the time to rollout a best
            roaming experience and have the control to build aggressive future
            roadmaps and rollouts. It overcomes the challenges of dealing with
            multiple vendors, technologies and ever evolving customer demands.
          </p>
        </div>
        <div className="feature_inner row">
          <div className="col-lg-4 col-md-6 d-flex align-content-stretch">
            <div className="feature_item text-center">
              <i className="flaticon-travel-1"></i>
              <h4>International Roaming Revenue Enhancement</h4>
              <p>
                Enhance roaming revenues through our advisory services for fraud
                detection and steering prevention
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 d-flex align-content-stretch">
            <div className="feature_item text-center">
              <i className="flaticon-iot"></i>
              <h4>IOT Negotiations</h4>
              <p>
                Decades of domain expertise and connects get you the best Inter
                Operator Tariffs
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 d-flex align-content-stretch">
            <div className="feature_item text-center">
              <i className="flaticon-technology"></i>
              <h4>IREG/TADIG process streamlining</h4>
              <p>
                Gap analysis and tailored framework creation on a case to case
                basis for roaming test streamlining
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 d-flex align-content-stretch">
            <div className="feature_item text-center">
              <i className="flaticon-engineering"></i>
              <h4>Automation</h4>
              <p>
                Phase wise automation for test roaming testing and tariff
                settlements
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 d-flex align-content-stretch">
            <div className="feature_item text-center">
              <i className="flaticon-diploma"></i>
              <h4>Production stage Quality Assurance</h4>
              <p>
                Operational health and hygiene checks on a periodic basis along
                with system audits
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 d-flex align-content-stretch">
            <div className="feature_item text-center">
              <i className="flaticon-website"></i>
              <h4>Reporting &amp; Insights</h4>
              <p>
                Provide reports and insights to enable cost reduction and focus
                on high revenue channels/countries/partners
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default HomeServices
