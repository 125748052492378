import React from "react"
import { Link } from "gatsby"

import YAMLdata from "./home-about.yaml"

const HomeAbout = () => {
  return (
    <section className="feature_area p_120">
      <div className="container">
        <div className="main_title">
          <h2>{YAMLdata.title}</h2>
          <p>{YAMLdata.content}</p>
          <p>
            <br />
          </p>
          <Link to="about-us" className="genric-btn circle arrow">
            find out more
          </Link>
        </div>
      </div>
    </section>
  )
}
export default HomeAbout
