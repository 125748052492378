import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import HomeBanner from "../sections/home-banner"
import HomeAbout from "../sections/home-about"
import HomeSolutions from "../sections/home-solutions"
import HomeServices from "../sections/home-services"
import HomeProducts from "../sections/home-products"
import HomeConsulting from "../sections/home-consulting"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" description="Get LTE ready with no hardware cost" />
    <HomeBanner />
    <HomeAbout />
    <HomeSolutions />
    <HomeServices />
    <HomeProducts />
    <HomeConsulting />
  </Layout>
)

export default IndexPage
